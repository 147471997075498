import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import { ROUTES } from '../shared'
import MainLayout from '../widgets/main-layout'
import DemoPage from './demo-page'

const router = createBrowserRouter([
  {
    path: ROUTES.HOME
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.VR_DEMO.path,
        element: <DemoPage />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.HOME} />
  }
])

const Pages = () => {
  return <RouterProvider router={router} />
}

export default Pages
