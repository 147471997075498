import axios from 'axios'

import { ACCESS_DEMO_TOKEN_NAME, API_ROUTES } from '../constants'
import { TokenResponse } from '../types'

export const refreshDemoToken = async () => {
  const { data } = await axios.post<TokenResponse>(
    `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/${API_ROUTES.LOGIN_DEMO_USER}`
  )
  sessionStorage.setItem(ACCESS_DEMO_TOKEN_NAME, data.accessToken)
  return data.accessToken
}

export const getCurrentToken = async () => {
  const accessDemoToken = sessionStorage.getItem(ACCESS_DEMO_TOKEN_NAME)
  if (accessDemoToken) return accessDemoToken

  return await refreshDemoToken()
}

export const refreshCurrentToken = async () => {
  return await refreshDemoToken()
}
