import { Provider } from 'react-redux'

import Pages from '../pages'
import { store } from './store'
import './styles.css'

const App = () => {
  return (
    <Provider store={store}>
      <Pages />
    </Provider>
  )
}

export default App
