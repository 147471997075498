import { VrDemo } from 'beyond-words-vr-module'
import { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useLazyGetActivityQuery } from '../../app/api'
import Loader from '../../shared/ui/loader'
import './styles.css'

const DemoPage: FC = () => {
  const params = useParams<{
    activityId: string
  }>()
  const [getActivity, { isLoading, data }] = useLazyGetActivityQuery()

  const completeCallback = useCallback(() => {
    location.reload()
  }, [])

  useEffect(() => {
    sessionStorage.clear()
    getActivity({ activityId: params.activityId as string })
  }, [getActivity, params.activityId])

  if (isLoading || !data) return <Loader />

  return (
    <div className="demo-page">
      <VrDemo
        activityMode={data.mode}
        questions={data.questions}
        sceneUrl={data.sceneUrl}
        language={data.unit.course.language || 'en'}
        completeCallback={completeCallback}
      />
    </div>
  )
}

export default DemoPage
