import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosRequestConfig } from 'axios'

import {
  API_ROUTES,
  getCurrentToken,
  IActivity,
  IErrorResponse,
  refreshCurrentToken
} from '../../shared'
import { Config } from '../../shared/config'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    AxiosRequestConfig,
    unknown,
    IErrorResponse | Pick<IErrorResponse, 'message'>
  > =>
  async ({ url, ...args }) => {
    const token = await getCurrentToken()

    const originalRequest = async (currentToken: string) => {
      const result = await axios({
        url: `${baseUrl}/${url}`,
        headers: { Authorization: `Bearer ${currentToken}` },
        ...args
      })
      return { data: result.data }
    }

    try {
      return await originalRequest(token)
    } catch (error: any) {
      const statusCode = error.response.status
      if (token && statusCode === 401) {
        const newToken = await refreshCurrentToken()
        return await originalRequest(newToken)
      }

      return {
        error: {
          message: error.response.statusText,
          statusCode
        }
      }
    }
  }

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.apiUrl
  }),
  tagTypes: ['Activity'],
  endpoints: (builder) => ({
    getActivity: builder.query<IActivity, { activityId: string }>({
      query: ({ activityId }) => ({
        url: API_ROUTES.DEMO_ACTIVITY(activityId),
        method: 'GET'
      }),
      providesTags: ['Activity']
    })
  })
})

export const { useGetActivityQuery, useLazyGetActivityQuery } = api
export default api
