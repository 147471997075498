export const ROUTES = {
  HOME: '/',
  VR_DEMO: {
    path: '/vr/:activityId',
    get: (activityId: string) => `/vr/${activityId}`
  }
}

export const API_ROUTES = {
  DEMO_ACTIVITY: (activityId: string) => `activities/${activityId}/demo`,
  LOGIN_DEMO_USER: 'auth/login/demo'
}
