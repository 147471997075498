import { CircularProgress } from '@mui/material'
import React, { memo } from 'react'

import { Theme } from '../../constants'
import './styles.css'

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <CircularProgress size={90} style={{ color: Theme.colors.sky }} />
    </div>
  )
}

export default memo(Loader)
